<template>
    <div v-cloak>
        <PageLoader v-if="isLoading"></PageLoader>

        <div class="report-container column-report">
            <div class="mt-10">
            </div>

            <div class="row my-20 justify-content-between mr-3">
                <div></div>
                <div class="d-flex justify-content-end">
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#0b36dc">Verified</span>
                    <span class="text-white p-2 mx-2 align-self-md-center _price-backup"
                        style="background-color:#FF6F16">Semi-Verified</span>
                    <div class="text-white bg-danger p-2 mx-2 align-self-md-center _price-backup">Estimated</div>
                </div>
                <div class="fields-toggle-wrapper" v-if="custom_header_field">
                    <div class="fields-toggle-block selected-fields">
                        <div class="row justify-content-between">
                            <div class="fields-toggle-title">Shown Data Point</div>
                            <font-awesome-icon
                                style="font-size: 20px;cursor: pointer;position: absolute;top: 12px;right: 12px;"
                                class="text-danger" @click="custom_header_field = false"
                                :icon="['fas', 'times-circle']" />
                        </div>
                        <draggable class="list-group row justify-content-center" :list="fields" group="field"
                            @change="fieldsOn" :sort="true">
                            <template v-for="(element, index) in fields">
                                <div :class="{ 'd-none': ['project', 'selected'].includes(element.label.toLocaleLowerCase()) }"
                                    class="list-group-item _cursor-pointer" :key="'element-a123' + element.label">
                                    {{ --index }}. {{ element.label }}</div>
                            </template>

                        </draggable>
                    </div>
                    <div class="fields-toggle-bar"></div>
                    <div class="fields-toggle-block">
                        <div class="fields-toggle-title">Drag and Drop Data Points</div>
                        <draggable class="list-group row" :list="list2" group="field" :sort="true">
                            <div class="list-group-item _cursor-pointer" v-for="(element) in list2"
                                :key="'element-a234' + element.label">{{
                    element.label }}</div>
                        </draggable>
                    </div>
                </div>
            </div>
            <div class="row my-4 ml-1 justify-content-between align-items-center">
                <div></div>
            </div>
            <div class="row mb-3 justify-content-between">
                <div>
                    <h1 class="ml-3 text-vb-secondary">UNIT REPORT</h1>                
                </div> 
                <div>
                    <button @click="exportData" class="btn btn-vb-primary">Export CSV <font-awesome-icon class="mx-2 type-icons-fontawesome text-vb-light" :icon="['fas', 'file-export']"/> </button>      
                </div>
            </div>
            
            <div class="row">
                    <div class="col-md-4">
                        <treeselect class="border border-vb-secondary mb-10 pb-1 rounded" v-model="filter_rows.filterArray" :multiple="true" :options="filter_options" value-consists-of="LEAF_PRIORITY" 
                        placeholder="Choose filter"/>
                        </div>
                    <div class="col-md-8">
                        <div class="row align-items-center justify-content-end">
                            <div class="col-md-6">
                                <b-form-input v-model="filter_rows.filterText" placeholder="Filter project" debounce="500"></b-form-input>
                            </div>
                            <div class="mt-0"> 
                                <img class="print-none mx-2 mt-10" src="/images/assets/fields.png"
                                    @click="custom_header_field = !custom_header_field">
                                <img v-if="loading == false" @click="generateReport();" style="cursor: pointer; margin-right: 20px;" width="50" height="50" src="/images/assets/export_icon.png">
                                <b-button v-if="loading == true" variant="vb-primary" disabled>
                                    <b-spinner small type="grow"></b-spinner>
                                    Generating report...
                                </b-button>
                            </div>
                        </div>
                    </div>

            </div>
            <RpdTable id="my-table" v-model="totalRows" :totalRows="totalRows"
                :current-page="currentPage" :per-page="perPage" v-on:popHistory="popHistory" v-on:getFilteredData="getFilteredData"
                v-on:selectAllRows="selectAllRows" v-on:popModal="showModal" v-on:updateTotalRows="updateTotalRows" v-on:refreshResults="refreshResults"
                :row_data="mlsData" :header_fields="fields" :marketRentalPsfToDouble="marketRentalPsfToDouble" 
                :filter_rows="filter_rows" :filtered_data="filtered_data">
            </RpdTable>
            <div style="margin-bottom: 8rem !important;" class="row jutify-content-space-between">
                <div class="col">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" prev-text="Prev"
                    first-text="First" last-text="Last" size="md">
                        <template #next-text><font-awesome-icon :icon="['fas', 'caret-right']" /></template>
                        <template #prev-text><font-awesome-icon :icon="['fas', 'caret-left']" /></template>
                    </b-pagination>
                </div>
                <div class="px-3"> 
                    <b-form-select v-model="perPage" :options="rowOptions" class="mt-15"></b-form-select>
                </div>
            </div>
            <b-modal ref="floorplan_modal" header-bg-variant="vb-secondary" title="Floor Plan"
                v-for="(row, key) in mappedRow" :id="'mls-fp-' + row.field_mls_floor_plan_fid" hide-footer :key="key">
                <div v-if="row.floor_plan.fid" class="d-block text-center">
                    <p>
                        <a target="_blank" :href="row.floor_plan.link"><img class='floorplan-pdf'
                                :src="row.floor_plan.preview"></a>
                    </p>
                </div>
            </b-modal>
        </div>
        <b-modal id="mlspop" hide-footer @hidden="mlsHistory = null" title="Unit History">
            <SummaryPopUp :data="unitHistoryData" :mlsHistory="mlsHistory" />
        </b-modal>
        <!-- style="position: absolute; top:-20rem;" -->
        <ReportFooter id="erl-div" :userData="user_data" style="position: absolute; top:-20rem;"/>
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { BACKEND_URL } from '../variables.js';
import { BTabs, BTab, BPopover, BPopoverDirective, BModal, BModalDirective, VBModal, BIconFileEarmarkXFill } from 'bootstrap-vue';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { cloneDeep } from 'lodash';
import { useRequestCounter } from '../store/RequestCounter'
import draggable from 'vuedraggable';
import RpdTable from './common/RpdTable.vue';
import { useUserStore } from '../store/UserStore';
import { useUserDataStore } from '../store/UserDataStore';
import { mapState } from 'pinia';
import { BBadge } from 'bootstrap-vue'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { BSpinner } from 'bootstrap-vue'
import ReportFooter from './common/ReportFooter.vue';
import { text } from '@fortawesome/fontawesome-svg-core';
import html2canvas from 'html2canvas'; 
import PageLoader from '../components/common/PageLoader.vue';
import SummaryPopUp from './common/SummaryPopUp.vue';

export default {
    components: {
        DatePicker,
        'b-tabs': BTabs,
        'b-tab': BTab,
        'b-popover': BPopover,
        'b-modal': BModal,
        'b-badge': BBadge,
        'b-spinner': BSpinner,
        Treeselect,
        draggable,
        RpdTable,
        ReportFooter,
        PageLoader,
        SummaryPopUp
    },
    directives: {
        'b-popover': BPopoverDirective,
        'b-modal': VBModal,
    },
    data() {
        return {
            filtered_data:[],
            isLoading: false,
            rowOptions: [
                { value: 10, text: '10 rows' }, 
                { value: 25, text: '25 rows' }, 
                { value: 50, text: '50 rows' }, 
                { value: 100, text: '100 rows' }, 
            ],
            projectSelected: 0,
            arrayOfImages:[],
            loading:false,
            query: this.$route.query,
            unitHistoryData: {},
            mlsData: [],
            sortBy: 'sort-uname',
            orderAsc: true,
            mlsHistory: [], 
            filterSearch: null,
            filter_rows : {
                    filterText: '',
                    filterArray : []
                },
            filter_options : [
                {
                    id: 'type',
                    label: 'Type',
                    children: [
                        {id:'bed_Studio', label:'Studio'}, 
                        {id:'bed_Jr 1-bed', label:'Jr 1-bed'},
                        {id:'bed_1-bed', label:'1-bed'},
                        {id:'bed_2-bed', label:'2-bed'},
                        {id:'bed_3-bed', label:'3-bed'},
                        {id:'bed_4-bed', label:'4-bed'},
                    ]
                },
                {
                    id:'baths',
                    label:'Baths',
                    children:[    
                        {id:'bath_1', label:'Bath - 1'},
                        {id:'bath_1.5', label:'Bath - 1.5'},
                        {id:'bath_2', label:'Bath - 2'},
                        {id:'bath_2.5', label:'Bath - 2.5'},
                        {id:'bath_3', label:'Bath - 3'},
                        {id:'bath_3.5', label:'Bath - 3.5'},
                        {id:'bath_4', label:'Bath - 4'},
                        {id:'bath_4.5', label:'Bath - 4.5'},
                        {id:'bath_5', label:'Bath - 5'},
                    ]
                },
                {
                    id:'fpspecs',
                    label: 'FP Specs',
                    children:[
                        {id:'fp_Jr Bed', label:'Jr Bed'},
                        {id:'fp_Lock-Off', label:'Lock-Off'},
                        {id:'fp_Loft', label:'Loft'},
                        {id:'fp_PH', label:'PH'},
                        {id:'fp_Roof Deck', label:'Roof Deck'},
                        {id:'fp_Sub-PH', label:'Sub-PH'},
                    ]
                },
                {
                    id:'status',
                    label: 'Status',
                    children:[
                        {id:'st_Active', label:'Active'},
                        {id:'st_Sold', label:'Sold'},
                    ]
                },
                {
                    id: 'cs',
                    label: 'Construction Status',
                    children: [ {
                        id: 'cs_Pre-Construction',
                        label: 'Pre-Construction',
                    }, {
                        id: 'cs_Excavation & Foundation',
                        label: 'Excavation & Foundation',
                    }, {
                        id: 'cs_Framing',
                        label: 'Framing',
                    }, {
                        id: 'cs_Interior Finishing',
                        label: 'Interior Finishing',
                    }, {
                        id: 'cs_Some Homes Complete',
                        label: 'Some Homes Complete',
                    }, {
                        id: 'cs_Complete',
                        label: 'Complete',
                    } ],
                },
                {
                id: 'pt',
                label: 'Project Type',
                children: [ {
                    id: 'pt_Apartment',
                    label: 'Apartment',
                }, {
                    id: 'pt_Mixed-Use Apartment',
                    label: 'Mixed-Use Apartment',
                }, {
                    id: 'pt_Townhome',
                    label: 'Townhome',
                }, {
                    id: 'pt_Stacked Townhome',
                    label: 'Stacked Townhome',
                } ],
                },
                {
                    id: 'pdt',
                    label: 'Product Type',
                    children: [ {
                        id: 'pdt_Concrete',
                        label: 'Concrete',
                    }, {
                        id: 'pdt_Wood Frame',
                        label: 'Wood Frame',
                    }, {
                        id: 'pdt_Townhome',
                        label: 'Townhome',
                    }, {
                        id: 'pdt_Stacked Townhome',
                        label: 'Stacked Townhome',
                    } ],
                },
            ],
            fields: [],
            list2: [
                { label: "Balcony", key: 'balcony' },
                { label: "Balcony Area", key: 'balcony_area' },
                { label: "View Orientation", key: 'view_orientation' },
                { label: "Parking", key: 'parking' },
                { label: "Stalls", key: 'stalls' },
                { label: "Buyer Incentives", key: 'buyer_incentives' },
                { label: "Realtor Incentives", key: 'realtor_incentives' },
                { label: "Net Price", key: 'net_price' },
                { label: "Floor Level", key: 'floor_levels' },
                { label: "FP Specs", key: 'fp_specs' },
                { label: "Sold Date", key: 'sold_date' },
                { label: "Sold Price", key: 'sold_price' },
                { label: "Status", key: 'status_name_name' },
                { label: "Construction Status", key: 'construction_status' },
                { label: "Product Type", key: 'product_type' },
                { label: "Project Type", key: 'project_type' }
            ],
            custom_header_field: false,
            allSelected: false,
            filteredReportDetail: [],
            selectedSummary: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            useUserDataStore: useUserDataStore()
        }
    },
    methods: {
        getFilteredData(data) {
            this.filtered_data = data
        },
        exportData () {
                const vm = this 
                let cusData = vm.filtered_data
                let allData = cusData.map(item => ({
                ...item,
                customData: '[' +item.customData.projectName + '] ' + item.customData.project,
                customLocationData: '[' +item.customLocationData.address + '] ' + item.customLocationData.municipality,
                custom_field_mls_beds_value: '[' +item.custom_field_mls_beds_value.type + '] ' + item.custom_field_mls_beds_value.fp_specs,
                }));

                const csvContent = this.convertToCsv(allData)
                const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
                const url = URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'exported-data.csv')
                link.click()
                URL.revokeObjectURL(url); // Clean up the URL after download        
            },
        convertToCsv(data){ 
            const vm = this 

            const headers = vm.fields.filter(header => header.key != 'checkbox').map(item => item.label)  
            const rows = data.map(row => {
                return vm.fields.filter(header => header.key != 'checkbox').map(header => {
                    const cell = row[header.key];
                    return typeof cell === 'string' && cell.includes(',')
                    ? `"${cell.replace(/"/g, '""')}"` // Escape commas and quotes
                    : cell;
                    })
            })
            
            const headerRow = headers.join(",")
            const csvRows = [headerRow, ...rows.map(row => row.join(','))]
            return csvRows.join('\n')
        },
        async fetchUserData() {
            try {
                await this.useUserDataStore.getData();
            } catch (error) {
                console.error('Failed to fetch user data:', error);
            }
        },
        async generateReport () {
            const vm = this 
            
            const doc = new jsPDF('l','mm');

            let summaryReport = vm.mlsData 

            vm.loading = true
            let date = new Date();
            let formattedDate = vm.reportFormatDate(date)
            let logo = document.createElement('img');
            logo.src = 'src/assets/images/viber_image_2023-11-07_11-30-39-588.jpg';
            
            let rpdBackground = new Image()
                rpdBackground.src = '/images/assets/logo/RPD_logomark_black-1.png'
                rpdBackground.width = 200
                rpdBackground.height = 200
            
            const pageWidth = doc.internal.pageSize.width;
            const pageHeight = doc.internal.pageSize.height;

             
            doc.setFontSize(8)

            doc.text(formattedDate, 15, 10);
            doc.text("realpropertydata.ca", doc.internal.pageSize.width - 38, 10)
            doc.setLineWidth(0.5);
            doc.setDrawColor("#d9d9d9");
            doc.line(15, 20, doc.internal.pageSize.width - 15, 20);
            doc.setFontSize(14)
            doc.setFont("Helvetica", "bold")
            doc.text("Unit Summary Report", 15, 30) 
            doc.setLineWidth(0);
            doc.setDrawColor("#000");
            doc.line(15, 31, 67, 31);
            doc.setFontSize(12)
            let unitBreakDownHeaders = vm.fields.filter(x => x.key !== 'checkbox').map((item) => {return {...item}})
           
            const formattedSummary = summaryReport.map( (item) => {
                return {...item}
            } );
            
            const tableData = formattedSummary.map((item, index) => {
                return unitBreakDownHeaders.map(header => {
                    if (header.key == 'customData')
                        return `${item[header.key].projectName}` + '\n' + item[header.key].project 
                    
                    if (header.key == 'field_mls_list_price_value' || header.key == 'psf') {
                        let formatPrice = Math.round(Number(item[header.key])).toLocaleString();
                        return item[header.key] ? `$${formatPrice}` : '-';
                    }

                    if (header.key == 'customLocationData')
                        return `${item[header.key].address}` + '\n' + item[header.key].municipality
 
                    if (header.key == 'custom_field_mls_beds_value')
                    return `${item[header.key].type}` + '\n' + item[header.key].fp_specs
 
                    
                    return item[header.key] ? item[header.key] : '-';
                })
            })
                          
                    
                    autoTable(doc, {
                    startY: 50, 
                    tableWidth: 270,
                    headStyles: { fillColor: '#476E93' , valign:'center', cellPadding: {top: 3, bottom:5, left: 1, right:1}  }, 
                    columnStyles: { 
                            0: {halign: 'left', valign:'middle',cellPadding: {left: 3 } }
                    }, 
                    styles: { fontSize: 8, halign:'center', valign:'middle', cellPadding: 2}, 
                    head: [unitBreakDownHeaders.map(header => header.label)], body: tableData,
                    didParseCell: async(data) => {
                        const { doc, cell, column } = data; 
                        if(data.cell.raw == 'Project'){
                            data.cell.styles.halign = 'left' 
                        } 
                        this.enhanceWordBreak(doc, cell, column)
                    }, 
                    didDrawCell: async(data) => {

                    }
                    
                }) 
                
                const promise2 = new Promise((resolve) => {
                    this.arrayOfImages.forEach((x, index) => {
                    doc.addPage("l", 'mm')
                    const centerX = pageWidth / 2 - x.image.width / 2;
                    const centerY = pageHeight / 2 - x.image.height / 2; 

                    doc.setTextColor("#000000");
                    doc.setFontSize(12)
                    doc.setFont("Helvetica", "bold")
                    doc.text(x.project.name, 15, 30);
                    
                    doc.setFontSize(10)
                    doc.setFont("Helvetica", "normal")
                    doc.text('Unit #: ', 15, 35);
                    doc.text(x.project.field_mls_address_value, 35, 35);
                    doc.text('Price: ', 15, 40);
                    doc.text(x.project.price, 35, 40);
                    doc.text('PSF: ', 15, 45);
                    doc.text(x.project.psf, 35, 45);
                    doc.text('Price Date: ', 15, 50);
                    doc.text(x.project.priceDate, 35, 50); 
                    doc.addImage(x.image.src, centerX, centerY, x.image.width, x.image.height) 
                
                    });

                    return resolve("Images loaded")
                })

                const promise3 = new Promise((resolve) => {
                    
                    const pageCount = doc.internal.getNumberOfPages();   

                    for(var i = 1; i <= pageCount ; i++){ 
                        doc.setPage(i)
                        
                        doc.setFontSize(8)
                        doc.text('Page ' + i + ' of ' + String(pageCount), pageWidth - 30, pageHeight - 5); 
                        
                        let centerX = pageWidth / 2 - rpdBackground.width / 2;
                        let centerY = pageHeight / 2 - rpdBackground.height / 2;  
                        
                        doc.addImage(rpdBackground, 'PNG', centerX, centerY, rpdBackground.width, rpdBackground.height);
                        doc.addImage(logo, 'PNG', 10, doc.internal.pageSize.height - 14,50,10);     
                    }
                    
                    return resolve("Page numbers added")
                })
                Promise.all([promise2, promise3]).then((values) => {
                    this.loading = false
                    window.open(doc.output('bloburl'), '_blank',"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=100,left=500,width=1000,height=800");

                });
            },
            enhanceWordBreak(doc, cell, column) {
                if (cell === undefined) {
                return;
                }

                const hasCustomWidth = (typeof cell.styles.cellWidth === 'number');

                if (hasCustomWidth || cell.raw == null || cell.colSpan > 1) {
                return
                }

                let text;

                if (cell.raw instanceof Node) {
                text = cell.raw.innerText;
                } else {
                if (typeof cell.raw == 'object') {
                    // not implemented yet
                    // when a cell contains other cells (colSpan)
                    return;
                } else {
                    text = '' + cell.raw;
                }
                }

                // split cell string by spaces
                const words = text.split(/\s+/);

                // calculate longest word width
                const maxWordUnitWidth = words.map(s => Math.floor(doc.getStringUnitWidth(s) * 100) / 100).reduce((a, b) => Math.max(a, b), 0);
                const maxWordWidth = maxWordUnitWidth * (cell.styles.fontSize / doc.internal.scaleFactor)

                const minWidth = cell.padding('horizontal') + maxWordWidth;

                // update minWidth for cell & column

                if (minWidth > cell.minWidth) {
                cell.minWidth = minWidth;
                }

                if (cell.minWidth > cell.wrappedWidth) {
                cell.wrappedWidth = cell.minWidth;
                }

                if (cell.minWidth > column.minWidth) {
                column.minWidth = cell.minWidth;
                }

                if (column.minWidth > column.wrappedWidth) {
                column.wrappedWidth = column.minWidth;
                }
            },
            async getImageRatio(imgUrl) { 
                        
                    const max = {height:190,width:133} 

                    return new Promise(async (resolve) => {
                        let image = new Image(); 
                        image.src=imgUrl
                        
                        image.onload = async function () { 
                        const ratio=image.height/image.width;
                   
                        if(image.height>max.height||image.width>max.width){
                            if(image.height>image.width){
                                image.height=max.height;
                                image.width=image.height*(1/ratio);
                            // Making reciprocal of ratio because ration of height as width is no valid here needs width as height
                            }else if(image.width > image.height){
                                image.width=max.width;
                                image.height=image.width*ratio;
                            // Ratio is valid here 
                            } else {
                                image.width=max.width;
                                image.height=image.width*ratio
                            }
                         }    
                            resolve({
                                status: 'success',
                                image: image
                            })
                        }
                    })
                    
                     
            },
            reportFormatDate(dateVal) {
                    let vm = this
                    var newDate = new Date(dateVal);

                    var sMonth = vm.padValue(newDate.getMonth() + 1);
                    var sDay = vm.padValue(newDate.getDate());
                    var sYear = newDate.getFullYear();
                    var sHour = newDate.getHours();
                    var sMinute = vm.padValue(newDate.getMinutes());
                    var sAMPM = "AM";

                    var iHourCheck = parseInt(sHour);

                    if (iHourCheck > 12) {
                        sAMPM = "PM";
                        sHour = iHourCheck - 12;
                    }
                    else if (iHourCheck === 0) {
                        sHour = "12";
                    }

                    sHour = vm.padValue(sHour);

                    return sMonth + "/" + sDay + "/" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
            },
            
        padValue(value) {
                return (value < 10) ? "0" + value : value;
        },
        formatDate(date) {
            if (!date || date.length <= 0)
                return '-';

            let mlsDate = new Date(date * 1000);

            return mlsDate.toISOString().slice(0, 10).replace(/-/g, '-');
        },
        setRpdHeaderFields() {
            const vm = this
            

            switch(vm.current_user.role){ 
                default: 
                    vm.fields = [
                        { label: 'Selected', key: 'checkbox', thClass: 'w-small' },
                        { label: "Project & Developer", key: 'customData', thClass: 'text-left pl-3 w-25', sortable: true },
                        { label: "Location" , key: 'customLocationData',sortable: true },
                        { label: "Unit #", key: 'field_mls_address_value', sortable: true },
                        { label: "Plan Name", key: 'field_mls_plan_name_value', sortable: true },
                        { label: "Type", key: 'custom_field_mls_beds_value',sortable: true },
                        { label: "Baths", key: 'field_mls_baths_value',sortable: true },
                        {
                            label: "Unit Size", key: 'field_mls_unit_size_value', formatter: (value) => {
                                return Number(value) ? Math.round(value).toLocaleString() : '-'
                            },sortable: true
                        },
                        { label: "List Price", key: 'field_mls_list_price_value', formatter: this.roundToWholeNumber, sortable: true },
                        { label: "$PSF", key: 'psf', sortable: true },
                        { label: "Incentives", key: 'buyer_incentives', sortable: true },
                        { label: "Net $Price" , key: 'net_price',sortable: true },
                        { label: "Net $PSF" , key: 'net_psf',sortable: true },
                        { label: "Price Date", key: 'price_date', sortable: true }
                    ]
                break;

            }
            
        },
        selectAllRows({ selectAll, tableRef }) {
            this.filtered_data.forEach((filteredItem, index) => {  
                    this.mlsData.forEach(
                        (item) => {
                            if(item.entity_id === filteredItem.entity_id) {
                                item.rowSelected = selectAll
                            }
                        }
                    ); 
                })
        },
        updateTotalRows(value) {
            this.totalRows = value
        },
        roundToWholeNumber(value) {
            return Number(value) ? '$' + Math.round(value).toLocaleString() : '-'
        },
        showModal(data) {
            this.$root.$emit('bv::show::modal', 'mls-fp-' + data)
        },
        setColor(data) {
            let color = ""
            if (data) {
                if (data == 'Verified')
                    color = "#0b36dc"

                if (data == "Educated Estimated")
                    color = "#dc3545"

                if (data == "Semi-Verified")
                    color = "#FF6F16"

            }
            return color
        },
        refreshResults() { 
            const vm = this
            if (this.mlsData.filter(row => row.rowSelected === true).length > 0)
                this.mlsData = this.mlsData.filter(row => row.rowSelected === true)

                
            vm.arrayOfImages = new Array() 
            let promises = []
            vm.mlsData.forEach(x => { 
                promises.push(vm.getImageRatio(x.floor_plan.preview))
                
                vm.getImageRatio(x.floor_plan.preview).then(resolve => {
                    if(resolve.status == "success"){ 
                        
                        vm.arrayOfImages.push(
                            {   project: {
                                name: x.name,
                                field_mls_address_value: x.field_mls_address_value,
                                price: this.roundToWholeNumber(x.field_mls_list_price_value).toString() ,
                                psf: x.field_mls_psf_value > 0 ? this.roundToWholeNumber(x.field_mls_psf_value).toString() : '-',
                                priceDate: x.price_date
                                },
                                image: resolve.image
                            }
                        )
                    }
                })
            })
            Promise.all([promises]).then(value =>  {
                console.log(value)
            })
        },
        selectAll: function () {
            if (!this.allSelected) {
                for (let report in this.mlsSort) {
                    this.selectedSummary.push(this.mlsSort[report].entity_id);
                }
            }
            else {
                this.selectedSummary = [];
            }
        },
        fieldsOn: function () {
            // this.reportFields = [];
            // if (this.fields.length >= 10) {
            //     let poped = this.fields.pop();
            //     this.list2.push(poped);
            // } else {
            //     this.fields.forEach((val, key) => {
            //         this.reportFields.push(val.field);
            //     });
            // }
        },
        project_sort(column) {
            if (this.sortBy == column) {
                this.orderAsc = !this.orderAsc;
            }
            this.sortBy = column;
        },
        utcLink(utc) {
            let link = '/type?project=' + utc.city_entity_id + '&fid=' + utc.field_project_type_collection_value + '&sid=' + utc.field_utc_status_tid + '&uid=' + utc.field_unit_type_value;
            return link;
        },
        getMLSDetail(params) {
            const endPoint = `api/get_mls_list?${params}`;
            this.isLoading = true
            useRequestCounter().incrementRequestCounter();
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
                // this.mlsData = cloneDeep(data); 
                this.mlsData = data.filter(mlsRow => mlsRow.price_backup != 'Educated Estimated' && mlsRow.price_date.length > 0).map(mlsRow => {
                    return {
                        ...mlsRow,
                        psfToString: this.roundToWholeNumber(mlsRow.psf).toString(),
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        }, 
                        customLocationData:{
                            address: mlsRow.address,
                            municipality: mlsRow.city,
                            neighbour: mlsRow.neighbour
                        },
                        custom_field_mls_beds_value: {
                            type : mlsRow.field_mls_beds_value == 's' ? 'Studio' : mlsRow.field_mls_beds_value == 'jr' ? 'Jr 1' + '-bed' : mlsRow.field_mls_beds_value + '-bed', 
                            fp_specs: mlsRow.fp_specs.toLocaleLowerCase() != 'none' ? mlsRow.fp_specs : ''
                        },
                        rowSelected: false
                    };
                })
                this.isLoading = false
                useRequestCounter().incrementCompletedRequest();
            });
        },
        popHistory(data) {
            const vm = this
            vm.unitHistoryData = data;
            let entry = 'eid=' + data.entity_id;
            const endPoint = `api/get_mls_history?${entry}`;
            $.get({
                url: BACKEND_URL + endPoint,
            }, (data, status) => {
                if (status !== 'success') return;
                this.mlsHistory = data;
            });
        }
    },
    computed: {
        ...mapState(useUserStore, ['current_user']),
        ...mapState(useUserDataStore, ['user_data']),
        marketRentalPsfToDouble: function() { 
            const vm = this;
                if(vm.$router.currentRoute.fullPath.includes('mls-list') && vm.$route.query['unittype[]'] == "2954")
                    return true

                    return false
        },
        isAdmin: function () {
            return this.current_user.role == 'admin'
        },
        filteredFloorPlan: function(){
            return this.mlsData.filter(x => x.floor_plan.length != 0)
        },
        mappedRow: {
            get: function () {
                return this.mlsData.map(mlsRow => {
                    return {
                        ...mlsRow,
                        customData: {
                            project: mlsRow.developer,
                            projectName: mlsRow.name,
                            href_link: this.utcLink(mlsRow)
                        },
                        rowSelected: false
                    };
                })
            },
            set: function (newVal) {
                return newVal
            }
        },
        mlsSort: {
            get: function () {
                let alphSorting = {
                    'sort-uname': 'name',
                    'location': 'address',
                    'sort-status': 'status_name_name',
                    'sort-unit': 'field_mls_address_value',
                    'sort-beds': 'field_mls_beds_value',
                    'sort-baths': 'field_mls_baths_value',
                    'sort-usize': 'field_mls_unit_size_value',
                    'sort-price': 'price',
                    'sort-psf': 'psf',
                    'sort-date': 'price_date'
                }
                var vm = this;
                var ob = this.mlsData;
                Object.keys(alphSorting).forEach(function (k) {
                    if (k == vm.sortBy) {
                        if (vm.orderAsc) {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] > b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] > a[alphSorting[k]]) ? -1 : 0));
                        }
                        else {
                            ob = vm.mlsData.sort((a, b) => (a[alphSorting[k]] < b[alphSorting[k]]) ? 1 : ((b[alphSorting[k]] < a[alphSorting[k]]) ? -1 : 0));
                        }
                    }
                });
                return ob;
            },
            set: function (newValue) {
                this.mlsData = newValue
            }

        }
    },
    watch: {
        mlsData: function (newVal) {
            this.totalRows = newVal.length
        },
    },
    mounted: function () {

        let param = ''
        var vm = this;
        vm.fetchUserData();
        let rangList = [
            'mlsbedsmax',
            'mlsbedsmin',
            'mlsbathsmax',
            'mlsbathsmin',
            'mlspricemax',
            'mlspricemin',
            'mlspricemax',
            'mlsunitmax',
            'mlsunitmin'
        ];
        vm.setRpdHeaderFields()
        rangList.forEach((d) => {
            if (vm.query[d]) {
                param += d + '=' + vm.query[d] + '&';
            }
        });
        let dateList = [
            'listmlsdate',
            'soldmlsdate'
        ];
        dateList.forEach((d) => {
            if ($.isArray(vm.query[d + '[]'])) {
                vm.query[d + '[]'].forEach((a) => {
                    param += d + '[]=' + a + '&';
                });
            }
            else if (vm.query[d + '[]']) {
                param += d + '[]=' + vm.query[d + '[]'] + '&';
            }
        });

        if ($.isArray(vm.query['gut[]'])) {
            vm.query['gut[]'].forEach((a) => {
                param += 'gut[]=' + a + '&';
            });
        }
        else {
            if (vm.query['gut[]']) {
                param += 'gut[]=' + vm.query['gut[]'] + '&';
            }
        }

        if ($.isArray(vm.query['fp[]'])) {
            vm.query['fp[]'].forEach((a) => {
                param += 'fp[]=' + a + '&';
            });
        }
        else {
            if (vm.query['fp[]']) {
                param += 'fp[]=' + vm.query['fp[]'] + '&';
            }
        }

        if ($.isArray(vm.query['city[]'])) {
            vm.query['city[]'].forEach((a) => {
                param += 'city[]=' + a + '&';
            });
        }
        else {
            if (vm.query['city[]']) {
                param += 'city[]=' + vm.query['city[]'] + '&';
            }
        }


        if ($.isArray(vm.query['unittype[]'])) {
            vm.query['unittype[]'].forEach((a) => {
                param += 'unittype[]=' + a + '&';
            });
        }
        else {
            if (vm.query['unittype[]']) {
                param += 'unittype[]=' + vm.query['unittype[]'] + '&';
            }
        }

        if ($.isArray(vm.query['type[]'])) {
            vm.query['type[]'].forEach((a) => {
                param += 'type[]=' + a + '&';
            });
        }
        else {
            if (vm.query['type[]']) {
                param += 'type[]=' + vm.query['type[]'] + '&';
            }
        }

        if ($.isArray(vm.query['status[]'])) {
            vm.query['status[]'].forEach((a) => {
                param += 'status[]=' + a + '&';
            });
        }
        else {
            if (vm.query['status[]']) {
                param += 'status[]=' + vm.query['status[]'] + '&';
            }
        }

        if ($.isArray(vm.query['mlsdate[]'])) {
            vm.query['mlsdate[]'].forEach((a) => {
                param += 'mlsdate[]=' + a + '&';
            });
        }
        else {
            if (vm.query['mlsdate[]']) {
                param += 'mlsdate[]=' + vm.query['mlsdate[]'] + '&';
            }
        }

        this.getMLSDetail(param)

        this.filteredReportDetail = this.mlsData

    }
}
</script>
<style scoped>
.vue-treeselect__multi-value-item {
    background: #426586 !important;
    border-radius: 7px;
    color: #fff !important;
    font-size: 0.8rem;
    font-weight: bold;
}
th>div {
    position: relative;
}

th>div>span {
    white-space: nowrap;
}

th>div>div {
    position: absolute;
}

fa {
    font-size: 18px;
}

th ul {
    border: 1px solid #eee;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 24px;
    transform: translateX(-50%);
    overflow: hidden;
}

.visible {
    max-height: 100px;
    transition: max-height .3s;
}

.invisible {
    max-height: 0px;
    transition: max-height .3s;
}

.rotate-180 {
    transform: rotate(180deg);
    transition: transform .3s;
}

.rotate-360 {
    transform: rotate(360deg);
    transition: transform .3s;
}

th ul li {
    background: #fff;
    padding: 8px 16px;
    border-bottom: 1px solid #dee2e6;
    color: #212529;
    text-transform: none;
    font-size: 12px;
    white-space: nowrap;
    height: 32px;
    text-align: left;
    cursor: pointer;
}

th ul li:hover {
    background: #4db7fe;
    color: #fff;
}

th ul li:last-child {
    border-bottom: none;
}

.selected {
    background: #4db7fe;
    color: #fff;
}

a {
    color: #007bff;
}

.report-container {
    padding: 30px 140px;
}

.status-btn {
    background: #EEF4FB;
    border-radius: 25px;
    font-size: 20px;
    line-height: 18px;
    color: #415A8F;
    padding: 10px 30px;
    margin-right: 30px;
    display: inline-block;
}

.status-row {
    margin-top: 20px;
    margin-bottom: 50px;
    text-align: left;
}

.status-line {
    line-height: 5;
}

.summary-table-header-row {
    cursor: pointer;
}

.list-table thead.summary-table-header tr.summary-table-header-row th {
    top: 0;
    padding: 2.5rem 1.5rem;
    font-family: Poppins;
    font-weight: 500;
    font-size: 1.2rem;
    z-index: 98;
    vertical-align: middle;
    position: sticky;
    background: #476e93;
    color: #fff;
    line-height: 24px;
}

.table.list-table tbody tr td {
    font-family: Poppins;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 30px;
    padding: 1.5rem;
    vertical-align: middle;
    border-bottom: 2px solid #545454;
    border-top: none;
}

.print-none {
    cursor: pointer;
    height: fit-content;
    max-width: 50px;
}

.fields-toggle-title {
    color: #13293E;
    font-size: 1.4rem;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.fields-toggle-wrapper {
    zoom: 1.4;
    border: 3px #13293E solid;
    position: absolute;
    right: 70px;
    width: 450px;
    z-index: 999;
    background: #F8F7F7;
    margin-top: 56px;
    padding: 30px;
}

.selected-fields .list-group-item {
    background: #FC5868;
    color: #fff;
}

.fields-toggle-bar {
    border-bottom: 3px solid #13293E;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.list-group-item {
    background: #D9D9D9;
    color: #13293E;
    font-size: 0.9rem;
    border: none;
}

.list-group {
    flex-direction: inherit;
}

.list-group-item {
    width: calc(50% - 20px);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

input._red-checkbox {
    accent-color: #FF033E;
    height: 30px !important;
    width: 30px !important;
}

h1 {
    font-size: 3rem;
    font-family: Poppins;
    font-weight: 600;
}

/* MOBILE STYLING */
@media only screen and (max-width: 976px) {

    .print-none {
        width: 100%;
        max-width: 35px;
        height: 40px;
    }

    .report-container {
        padding: 30px 50px;
    }
}

.modal-body {
    justify-content: center;
}

.mls-floorplan {
    color: #4d7ce1;
    text-decoration: underline;
}

.close {
    color: white !important;
}

/* END OF MOBILE STYLING */
</style>